(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/xpert-btn-selector/assets/javascripts/r-system-buttons.js') >= 0) return;  svs.modules.push('/components/tipsen/xpert-btn-selector/assets/javascripts/r-system-buttons.js');
"use strict";

const {
  useCallback
} = React;
const {
  useSelector
} = ReactRedux;
const {
  analytics
} = svs.components;
const {
  selectHalfHedges,
  selectFullHedges
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  CollapsibleButtonGroup
} = svs.components.tipsen.collapsibleGroup;
const {
  getSystemLabelFromNumber
} = svs.components.sport.systems;
const XpertBtnRsys = _ref => {
  let {
    setSize,
    size,
    xpertSystems,
    isSystemDisabled = () => false
  } = _ref;
  const couponId = useCouponId();
  const currentHalfHedges = useSelector(state => selectHalfHedges(state, couponId, 0));
  const currentFullHedges = useSelector(state => selectFullHedges(state, couponId, 0));
  const allSpecialValues = [];
  const trackEventOnSetSize = useCallback(selectedSize => {
    analytics.trackEvent({
      category: "Nya tipsen - Fyll i med PIX!",
      action: "\xD6nskat system - ".concat(selectedSize),
      opt_label: location.pathname
    });
  }, []);
  const isValueDisabled = useCallback(rSys => isSystemDisabled({
    currentHalfHedges,
    currentFullHedges,
    system: rSys
  }), [isSystemDisabled, currentFullHedges, currentHalfHedges]);
  const render = useCallback(rSys => getSystemLabelFromNumber(rSys), []);
  return React.createElement(CollapsibleButtonGroup, {
    allValues: xpertSystems,
    clickCallback: trackEventOnSetSize,
    isValueDisabled: isValueDisabled,
    promotedValues: allSpecialValues,
    render: render,
    setValue: setSize,
    title: "V\xE4lj system",
    value: size
  });
};
setGlobal('svs.components.tipsen.xpertenBtnSelector.XpertBtnRsys', XpertBtnRsys);

 })(window);